@mixin middle {
  display: flex;
  justify-content: center;
  align-items: center;
};


// ---------------------------------
.registerDefaultBox {
  margin-top: 20px;
  margin-bottom: 50px;
  background-color: #f6f5e9;
  padding: 20px;
  box-sizing: border-box;
  
// register.content -----------------------------------      

  .register-content {
    width: 100%;
    flex-direction: column;
    padding-top: 10px;
    min-height: 50px;
    
    .register-inputbox {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    
      .register-name {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .register-input {
        width: 70%;
        
        input, .input {
          width: 100%;
          height: 50px;
          padding: 4px 10px;
          border: 1px solid #ccc;
          box-sizing: border-box;
          border-radius: 5px;
          color: #777;
          font-size: 18px;
          background-color: #fff;
        }
        .register-input-select {
          height: 50px;
        }
        
      }
      .register-selectInput {
        width: 70%;
        height: 50px;
        display: flex;
        justify-content: space-between;

        .sortSelectBox {
          width: 33%;
          height: 100%;
          border: 1px solid #ccc;
          box-sizing: border-box;
          border-radius: 5px;
          @include middle;
          background-color: #fff;
        }
        .sortSelectBox.selected {
          border: 2px solid #777;
        }
        .sortSelectBox:hover {
          border: 1px solid #777;
          cursor: pointer;
        }
      }
      // datepicker 커스텀 ---------------------
      .react-datepicker__month-container {
        width: 300px;
        font-size: 14px;

        .react-datepicker__header {
          .react-datepicker__day-names{
            display: flex;
            padding: 0px 12px;
            justify-content: space-between;
          }
        }
        .react-datepicker__month {
          .react-datepicker__week {
            display: flex;
            padding: 5px;
            justify-content: space-between;
            .react-datepicker__day--outside-month{
              color: #BDBDBD;
            }
          }
        }
      }
      
      // --------------------------------------------------
      .plus-minus-button{
        width: 50px;
        height: 50px;
        @include middle;
       
        p {
          width: 30px;
          height: 30px;
          font-size: 24px;
          @include middle;
          border: 1px solid #8C8C8C;
          border-radius: 5px;
        }
        p:hover {
          background-color: #8C8C8C;
        }
      }
      .autoComplete {
        width: 610px;
        position: absolute;
        right: 0;
        top: 50px;
        background-color: #FFF;
        border: 1px solid #8C8C8C;
        z-index: 9;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .dropDownList {
          height: 30px;
          display: flex;
          align-items: center;
        }
        .dropDownList.selected{
          width: 100%;
          background-color: #EAEAEA;
        }
      }
      .magnifyIcon {
        position: absolute;
        right: 15px;
        display: flex;
        align-items: center;
        padding: 5px;
        border: 1px solid #EAEAEA;
        border-radius: 5px;
        p {
          margin-right: 5px;
        }
      }
      .magnifyIcon:hover {
        cursor: pointer;
        opacity: 0.8;
        background-color: #BDBDBD;
      }
      .DaumPostBox {
        position: absolute;
        width: 610px;
        top: 0px;
        right: 0px;
        z-index: 9;
        background-color: #FFF;
        border: 1px solid #8C8C8C;
      }
    }

    .inputbox.white {
      background-color: #fff;
    }
    .selectButton {
      display: flex;
      margin: 5px;

      .selectButton-btn {
        width: 100px;
        height: 50px;
        background-color: #EAEAEA;
        margin-left: 10px;
        margin-right: 10px;
        @include middle;
      }
      .selectButton-btn.seleted {
        border: 2px solid #8C8C8C;
      }
    }
    
  }


}

// buttonbox ----------------------------------------

.buttonbox {
  width: 400px;
  height: 50px;
  left: 0;
  right: 0;
  margin: 20px auto;
  display: flex;
  justify-content: center;

  .button {
    width: 150px;
    height: 40px;
    background-color: #EAEAEA;
    border-radius: 10px;
    margin-left: 20px;
    margin-right: 20px;
    @include middle;

    p {
      font-weight: 700;
    }
  }
  .button:hover {
    background-color: #8C8C8C;
    cursor: pointer;
  }
}