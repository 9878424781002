@mixin middle {
  display: flex;
  justify-content: center;
  align-items: center;
};


.login {
  
  > .inner {
    @include middle;
    flex-direction: column;

    .container {
      width: 500px;
      margin: 50px 0;
      border: 2px solid #EAEAEA;
      border-radius: 5px;
      @include middle;
      flex-direction: column;
      padding: 20px;

      .title {
        width: 90%;
        h1 {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 10px;
        }
      }
      .stepnotice {
        width: 90%;
        margin: 30px 0;

        .currentbar {
          display: flex;
          align-items: center;

          p {
            height: 30px;
            color: #BDBDBD;
            font-size: 18px;
          }
          .current {
            border-bottom: 2px solid #33383f;
            color: #33383f;
            
          }
        }
        .rowbar {
          width: 100%;
          height: 1px;
          background-color: #EAEAEA;
        }
      }

      .agree_check {
        width: 90%;
        margin-bottom: 50px;

        .agree_check_tit {

          li {
            display: flex;
            justify-content: space-between;
            margin: 15px 0;

            span.checks {
              display: flex;
              align-items: center;
  
              label {
                margin-left: 5px;
              }
              label:hover {
                cursor: pointer;  
              }
            }
            span.checks:hover {
              cursor: pointer;
            }
            a {
              font-size: 14px;
              color: #8a8a8a;
            }
            a:hover {
              color: #5f5f5f;
            }
          }
          
        }

      }

      .noticebox {
        height: 50px;
        
      }

      h2 {
        margin-top: 10px;
        border-bottom: 2px solid #33383f;
        font-weight: 600;
      }
      .userCheckBox {
        width: 400px;
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
      }
      
      .inputbox {
        width: 450px;
        padding: 10px;
        @include middle();
        flex-direction: column;

        .link {
          .snsloginbox {
            width: 400px;
            margin: 10px 0;
            border: 1px solid #dedede;
            border-radius: 5px;
            padding: 10px;
            display: flex;
            align-items: center;
  
            img {
              width: 30px;
              height: 30px;
            }
            p {
              margin: 0;
              height: 30px;
              @include middle();
            }
          }
          .snsloginbox:hover {
            cursor: pointer;
            border: 1px solid #979797;
          }
        }
        

        p {
          width: 400px;
          margin-bottom: 5px;
          text-align: left;
          color: #293c56;
          font-weight: 600;

          span {
            color: #F15F5F
          }
        }
        .inputbox-btncover {
          width: 400px; 
          display: flex;
          align-items: center; 
          justify-content: space-between;

          .addBtn {
            min-width: 70px;
            text-align: center;
            border: 1px solid #d7d7d7;
            border-radius: 3px;
            padding: 3px 10px;
            font-size: 14px;
          }
          .addBtn:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        }
        

        .inputdefault {
          width: 400px;
          font-size: 15px;
          font-weight: normal;
          height: 40px;
          padding-left: 10px;
          box-sizing: border-box;
          border: none;
          border-bottom: 2px solid #E9E9E9;
          margin-bottom: 10px;
        }
        .inputdefault:focus {
          border-bottom: 1px solid #33383f;
        }
        .select {
          border-bottom: 1px solid #33383f;
        }
        > .textarea {
          width: 400px;
          min-height: 200px;
          margin-left: 5px;
          font-size: 15px;
          font-weight: normal;
          flex: 1;
          text-align: start;
          border: 1px solid #E9E9E9;
          box-sizing: border-box;
          border-radius: 5px;
          padding: 10px;
          outline: none;
          line-height: 25px;
        }
        .areaselect {
          border: 1px solid #33383f;
        }
        .checkInputCover {
          width: 90%;
          display: flex;
          align-items: end;
          margin-top: 10px;
          margin-bottom: 15px;
  
          .checkInput {
            width: 50%;
            display: flex;
            align-items: center; 
            justify-content: center;
            margin-right: 10px;
            .input {
              width: 20px;
              height: 20px;
            }
            > h5 {
              margin-left: 10px;
              flex: 1;
            }
          }
        }
        .subRow {
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      .buttonbox {
        width: 500px;
        height: 50px;
        margin-top: 10px;
        @include middle;

        .button {
          width: 420px;
          height: 100%;
          background-color: #33383f;
          border: 1px solid #ccc;
          box-sizing: border-box;
          border-radius: 5px;
          color: #fff;
          cursor: pointer;
          @include middle;
        }
        .button:hover {
          opacity: 0.8;
        }
      }
      .bottombox {
        width: 500px;
        height: 50px;
        @include middle;
        
        .cover {
          width: 420px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            border: 1px solid #d1d1d1;
            border-radius: 3px;
            padding: 5px 10px;
          }
          p:hover {
            opacity: 0.8;
            cursor: pointer;
          }
        }
        
      }

    }
    .container.add {
      height: 470px;
    }
  }
}

@media (max-width: 1000px) {


  .login { 

    > .inner {

      .container {
        width: 90vw;
        max-width: 500px;
        @include middle();
        flex-direction: column;

        .noticebox {
          
          p {
            font-size: 14px;
          }
        }

        .inputbox {
          width: 90%;
          
          p {
            width: 100%;
          }

          .inputdefault {
            width: 100%;
          }
          .inputbox-btncover {
            width: 100%;
          }
          > .textarea {
            width: 100%;
          }
          .link {
            width: 90%;
            .snsloginbox {
              width: 90%;
            }
          }
        }

        .buttonbox {
          width: 100%;
  
          .button {
            width: 90%;
          }

        }
        .bottombox {
          width: 100%;
          
          .cover {
            width: 90%;
          }
        }
      }

    }
  }
}


@media (max-width: 600px) {


  .login { 

    > .inner {

      .container {
       
        .agree_check {
  
          .agree_check_tit {
  
            li {
              flex-direction: column;
              a {
                margin-top: 10px;
                text-align: end;
              }
            }
          }
        }
      }
    }
  }
}