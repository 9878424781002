@mixin middle {
  display: flex;
  justify-content: center;
  align-items: center;
};

.pamphlet {
  padding-top: 30px;
  @media (max-width: 600px) { 
    padding-top: 10px;
  }

  > .inner {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1000px) { 
      flex-direction: column; 
    }

    .subpage__menu {
      width: 20%;
      margin-right: 32px;
      @media (max-width: 1000px) { 
        width: 100%;
      }
      @media (max-width: 600px) { 
        display: none;
      }
  
      .subpage__menu__title {
        position: relative;
        font-size: 24px;
        font-weight: 600;
        padding-left: 30px;
      }
  
      .subpage__menu__title:before {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        width: 4px;
        height: 24px;
        background: #33383f;
      }
  
      .subpage__menu__list {
          padding-top: 38px;
    
        .subpage__menu__item {
          display: block;
          width: 100%;
          padding: 21px 0 21px 20px;
          font-size: 18px;
          color: #000;
          border-bottom: 1px solid #cbcbcb;
          line-height: 1;
          box-sizing: border-box;
        }
    
        .subpage__menu__item:first-child {
            border-top: 1px solid #cbcbcb;
        }
    
        .subpage__menu__item:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    
        .subpage__menu__item--on {
            color: #fff;
            background: #33383f;
        }
    
        .subpage__menu__item--on:hover {
            text-decoration: none;
        }
        .subpage__menu__list__sub {
          padding: 10px 20px;
          border-bottom: 1px solid #cbcbcb;

          .subpage__menu__item__sub {
            margin: 15px 0;
            color: #aaa;
            @media (max-width: 1000px) { 
              width: 100%;
              height: 30px;
              @include middle();
              padding: 0;
            }
          }
          .subpage__menu__item__sub:hover {
            text-decoration: underline;
            cursor: pointer;
          }
          .subpage__menu__item--on__sub {
            font-weight: bold;
            color: #33383f;
            padding-bottom: 5px;
          }
        }
      }
      .menu__desktop {
        display: block;
        @media (max-width: 1000px) { 
          display: none;
        }
      }
      .menu__mobile {
        display: none;
        @media (max-width: 1000px) { 
          display: block;
        }
      }

    }
  
    .subpage__main {
      width: 75%;
      min-height: 100vh;
      @media (max-width: 1000px) { 
        width: 100%;
      }

      .subpage__main__title {
        display: flex;
        justify-content: space-between;
        @media (max-width: 1000px) { 
          margin-top: 30px;
          margin-bottom: 20px;
        }
        
        h3 {
          font-size: 30px;
          font-weight: 600;
          color: #33383f;
        }
        .shareBtnbox {
          padding: 10px;
          border: 1px solid #cbcbcb;
          border-radius: 5px;
          display: flex;
          align-items: center;
        }
        .shareBtnbox:hover {
          cursor: pointer;
          background-color: #ececec;
        }
      }

      .subpage__main__search {
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 10px;
        @media (max-width: 1000px) { 
          flex-direction: column;
          align-items: flex-start;
        }
        
        .subpage__main__select__box {
          width: 20%;
          display: flex;
          align-items: center;
          @media (max-width: 1000px) { 
            width: 100%;
          }
        }
        .subpage__main__search__box {
          display: flex;
          align-items: center;
          width: 80%;
          @media (max-width: 1000px) { 
            width: 100%;
          }

          .inputdefault {
            font-size: 15px;
            font-weight: normal;
            height: 35px;
            text-align: center;
            border: 1px solid #E9E9E9;
            box-sizing: border-box;
            border-radius: 5px;
            padding-left: 5px;
          }
          .width {
            width: 70%;
            text-align:left;
            @media (max-width: 1000px) { 
              width: 50%;
            }
            @media (max-width: 600px) { 
              width: 40%;
            }
          }
          .buttons {
            @include middle();
            @media (max-width: 600px) { 
              font-size: 14px;
            }

            .btn {
              padding: 5px 10px;
              border: 1px solid #E9E9E9;
              margin: 0 5px;
              border-radius: 5px;
              background-color: #4f5460;
              p {
                color: #fff;
              }
            }
            .btn:hover {
              cursor: pointer;
              border: 1px solid #333;
            }
          }
        }
      }
      .subpage__main__select {
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 10px;
        margin: 20px 0;
        
        .select-row {
          width: 100%;
          height: 50px;
          display: flex;
    
          .select-btn {
            width: 50%;
            padding: 20px;
            @include middle();
            border-bottom: 1px solid #d7d7d7;
            p {
              font-size: 20px;
            }
          }
          .select-btn:hover {
            cursor: pointer;
          }
          .selected {
            border: 1px solid #d7d7d7;
            border-bottom: 2px solid #FFF;
          }
        }
      
      }
    

      
      .subpage__main__content {
        padding-top: 30px;
        font-size: 20px;
        margin-bottom: 100px;
      
        .board .subpage__main__content {
          font-size: 16px;
        }

        .main__content {
          line-height: 1.9;
          position: relative;
          
          @media (max-width: 1000px) { 
            .desktop {
              display: none;
            }
            .mobile {
              display: flex;
              flex-direction: column;
            }
          }
          // 다크모드 버튼 --------------------------------------------------
          .switch-btn-box {
            position: fixed;
            top: 150px;
            right: 20px;
            width: 60px;
            height: 35px;
            z-index: 9;
            .switch-btn-text {
              width: 60px;
              font-size: 14px;
            }
            
            .switch-toggle-container {
              width: 50px;
              height: 24px;
              border-radius: 30px;
              background-color: rgb(233,233,234);}
              //.toggle--checked 클래스가 활성화 되었을 경우의 CSS를 구현
            .switch-toggle--checked {
              background-color: rgb(0,200,102);
              transition : 0.5s
            }
          
            .switch-toggle-circle {
              position: absolute;
              top: 1px;
              left: 1px;
              width: 22px;
              height: 22px;
              border-radius: 50%;
              background-color: rgb(255,254,255);
              transition : 0.5s
              //.toggle--checked 클래스가 활성화 되었을 경우의 CSS를 구현
            } 
            .switch-toggle--checked {
              left: 27px;
              transition : 0.5s
            }
          }
          // 리스트 ----------------------------------------
          .pamphlet__wrap--category {
            
            .pamphlet__title__row {
              width: 100%;
              display: flex;
              justify-content: space-between;
              border-bottom: 3px solid #f1f1f1;

              .pamphlet__title {
                padding: 8px 0;
                color: #000;
                font-size: 24px;
                line-height: 1;
                font-weight: 500;
                text-align: left;
              }
              .pamphlet__link {
                display: flex;
                align-items: center;
                font-size: 16px;

                &:hover {
                  opacity: 0.7;
                  cursor: pointer;
                }
              }
            }
            .pamphlet__wrap--item {
              display: grid;
              justify-content: space-between;
              grid-template-columns: 33% 33% 33%;
              padding: 20px;
              @media (max-width: 1000px) { 
                grid-template-columns: 50% 50%;
              }
              @media (max-width: 600px) { 
                padding: 0;
              }

              .pamphlet__item {
                width: 100%;
                text-align: center;
                padding: 0 20px 20px 0;
                box-sizing: border-box;
                margin: 20px 10px;
                transition: transform 0.7s ease;

                .pamphlet__img--cover {
                  position: relative;
                  margin-bottom: 10px;
                  border-radius: 5px;

                  .imageBox {
                    width:100%;
                    
                    background-color:#fff;
                    @include middle();
                    border-radius: 10px;

                    img {
                      width: 100%;
                      border-radius: 10px;
                      object-fit: contain;
                    }
                  }
                }
                .namecard {
                  width: 80px;
                  height: 30px;
                  @include middle();
                  border: 1px solid #BDBDBD;
                  border-radius: 15px;
                  box-sizing: border-box;

                  p {
                    font-size: 16px;
                    color: #333;
                  }
                }
                .pamphlet__coname {
                  font-size: 22px;
                  font-weight: 600;
                  text-align: left;
                }
                .pamphlet__name {
                  font-size: 14px;
                  display: flex;
                  align-items: center;
                  @media (max-width: 600px) { 
                    font-size: 12px;

                    .pamphlet__name-title {
                      min-width: 25px;
                      font-weight: 600;
                    }
                  }

                  .pamphlet__divider {
                    width: 2px;
                    height: 15px;
                    background-color: #BDBDBD;
                    margin: 0 10px;
                    @media (max-width: 600px) { 
                      margin: 0 5px;
                    }
                  }
                }
                
              }
              .pamphlet__item:hover { 
                cursor: pointer;
                transform: scale(1.05);
              }
            }
            
          }

          // 디테일 ------------------------------------------------------------------------------
          // mainTitleBox ---------------
          .main-title-box {
            h1 {
              display: none;
            }
            h2 {
              display: none;
            }
            h3 {
              font-size: 30px;
              margin-bottom: 50px;
            }
            @media (max-width: 600px) {
              height: 500px;
              @include middle();
              flex-direction: column;
              white-space: pre-line;
              text-align: center;
              h1 {
                display: block;  
                font-size: 50px;
                font-family: DancingScript;
                font-weight: bold;
                 line-height: 70px;
                margin-bottom: 30px;
              }
              h2 {
                display: block;
                font-size: 25px;
                font-family: sans-serif;
                font-weight: 300;
                margin-bottom: 20px;
                line-height: 40px;
                color: #a97933;
              }
              h3 {
                font-size: 18px;
                font-family: sans-serif;
                white-space: pre-line;
              }
            }
          }

          // selectBox ---------------
          .selector__wrapper {
            margin: 50px auto;
            @media (max-width: 1000px) {
              width: 100%;
              margin-bottom: 50px;
            }
            width: 100%;
            display: grid;
            grid-template-columns: 25% 25% 25% 25%;
            grid-template-areas: 30px;
            
            .selector__bar {
              display: flex;
              height: 50px;
              justify-content: center;
              color: #848484;
              border-bottom: 1px solid rgba(228, 228, 228, 1);
              font-size: 21px;
              font-weight: 700;
              @media (max-width: 1000px) {
              font-size: 18px;
              font-weight: 700;
              }
              line-height: 1.6;
              position: relative;
              overflow: hidden;
        
              &::after {
                content: '';
                display: block;
                position: absolute;
                left: 50%;
                bottom: 0;
                width: 0;
                height: 2px;
                transform: translateX(-50%);
                transition: width 0.4s ease;
                background-color: #a97933;
              }
        
              &:hover::after,
              &.on::after {
                width: 100%;
              }
              &:hover {
                color: #a97933;;
                cursor: pointer;
              }
        
              &.on {
                color: #a97933;
        
                &::after {
                  content: '';
                  display: block;
                  position: absolute;
                  left: 50%;
                  bottom: 0;
                  width: 100%;
                  height: 3px;
                  transform: translateX(-50%);
                  background-color: #a97933;
                }
              }
            }
            
          }

          // notice -----------------------
          .noticebox{
            width: 100%;
            margin-top: 50px;
            margin-bottom: 100px;
            text-align: center;
                
            .noticebox-sub {
        
              .noticebox-sub-info {
                width: 100%;
                margin: 20px 0;
        
                li.notice-list{
                  display: flex;
                  min-height: 70px;
                  align-items: center;
                  border-bottom: 1px solid #EAEAEA;
                  font-weight: bold;
        
                  p {
                    font-weight: 500;
                  }
                  .notice-title {
                    width: 20%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media (max-width: 600px) { 
                      width: 30%;
                      justify-content: start;
                    }

                    .notice-title-icon {
                      width: 40%;
                      font-size: 22px;
                      @media (max-width: 1000px) { 
                        font-size: 20px;
                      }
                      @media (max-width: 600px) { 
                        width: 30%;
                        font-size: 18px;
                      }
                    }
                    .notice-title-text {
                      width: 60%;
                      font-size: 20px;
                      font-weight: 600;
                      text-align: left;
                      @media (max-width: 1000px) { 
                        font-size: 18px;
                      }
                      @media (max-width: 600px) { 
                        font-size: 16px;
                      }
                    }
                  }
                  .notice-bar {
                    width: 1px;
                    height: 20px;
                    background-color: #d1d1d1;
                  }
                  .notice-content {
                    width: 80%;
                    font-size: 20px;
                    padding: 0 10px;
                    @media (max-width: 1000px) { 
                      font-size: 18px;
                    }
                    @media (max-width: 600px) { 
                      width: 70%;
                      font-size: 16px;
                      padding: 5px 15px;
                    }
                  }
                  .notice-content-check {
                    font-size: 22px;
                    font-weight: 600;
                    @media (max-width: 1000px) { 
                      font-size: 20px;
                    }
                    @media (max-width: 600px) { 
                      font-size: 18px;
                    }
                  }
                }
              }
              .noticebox-sub-image {
                width: 100%;
                min-height: 500px;
        
                .notice-image{
                  width: 100%; 
                  height: 100%; 
                  overflow: hidden;
                  padding-top: 10px;
                  @include middle;
              
                  img {
                    width: 100%; 
                  }
        
                }
              }
            }
            .noticebox-mapBox {
              margin-top: 50px;
              padding: 20px;

              .noticebox-maptitle {
                display: flex;
                justify-content: space-between;
                @media (max-width: 600px) { 
                  flex-direction: column;
                  .for-pc {
                    display: none;
                  }
                }
              
                .noticebox-maptitle-divider {
                  width: 100%;
                  height: 1px;
                  background-color: #333;
                  margin-bottom: 10px;
                }
                .noticebox-maptitle-left {
                  width: 20%;
                  font-size: 24px;
                  font-weight: 600;
                  @media (max-width: 1000px) { 
                    font-size: 20px;
                  }
                  @media (max-width: 600px) { 
                    width: 100%;
                    text-align: left;
                  }
                }
                .noticebox-maptitle-right {
                  width: 75%;
                  text-align: left;
                  @media (max-width: 600px) { 
                    width: 100%;
                    text-align: center;
                  }

                  .noticebox-maptitle-right-text {
                    display: flex;  
                    align-items: center;
                    @media (max-width: 600px) { 
                      flex-direction: column;
                    }

                    .noticebox-maptitle-right-text-title {
                      font-size: 24px;
                      font-weight: 600;
                      @media (max-width: 1000px) { 
                        font-size: 20px;
                      }
                    }
                    .noticebox-maptitle-divider-vertical {
                      width: 2px;
                      height: 20px;
                      background-color: #333;
                      margin: 0 20px;
                    }
                  } 
                }
              }
              .noticebox-mapBtnBox {
                width: 100%;
                margin-top: 50px;
                margin-bottom: 50px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media (max-width: 600px) { 
                  flex-direction: column;
                }

                .noticebox-mapBtn {
                  width: 30%;
                  height: 80px;
                  display: flex;
                  align-items: center;
                  border: 1px solid #BDBDBD;
                  padding: 10px;
                  box-sizing: border-box;
                  border-radius: 5px;
                  @media (max-width: 1000px) { 
                    height: 60px;
                    margin-bottom: 20px;
                  }
                  @media (max-width: 600px) { 
                    width: 90%;
                  }

                  .noticebox-mapBtnImg {
                    width: 20%;
                    @media (max-width: 1000px) { 
                      height: 60px;
                      @include middle();
                      p {
                        width: 80%;
                        font-size: 16px;
                      }
                    }
                    img {
                      width: 50px;
                      @media (max-width: 1000px) { 
                        width: 50px;
                      }
                    }
                    .noticebox-icon {
                      width: 50px;
                      height: 50px;
                      border: 1px solid #BDBDBD;
                      border-radius: 25px;
                      @include middle();
                      @media (max-width: 1000px) { 
                        width: 40px;
                        height: 40px;
                        border-radius: 20px;
                      }
                    }
                  }
                  .noticebox-map-text {
                    width: 80%;
                  }
                  .noticebox-map-icon {
                    width: 10%;
                    color: #8C8C8C;
                  }
                }
                .noticebox-mapBtn:hover {
                  border: 1px solid #919191;
                }
              }
            }
            .noticebox-pamphletImages-showBtn-cover {
              width: 100%;
              @include middle();
              .noticebox-pamphletImages-showBtn {
                width: 250px;
                height: 80px;
                border: 1px solid #BDBDBD;
                border-radius: 5px;
                @include middle();
                flex-direction: column;
                color: #8a8a8a;
              }
              .noticebox-pamphletImages-showBtn:hover {
                cursor: pointer;
                border: 1px solid #8a8a8a;
              }
            }
            .noticebox-pamphletImages-cover {
              width: 100%;
              margin-bottom: 50px;
              img {
                width: 100%;
              }
            }
          }

          // message -------------------------------------------------------------------------------------
          .messagebox {
            width: 100%;
            margin-top: 50px;
            margin-bottom: 100px;
            text-align: center;
            @include middle;
            flex-direction: column;
      
 
            .message-container {
              margin-top: 20px;
              margin-bottom: 30px;
      
              .message-content {
                width: 100%;
                margin-bottom: 50px;
                
                .message-Title {
                  height: 50px;
                  border-top: 1px solid #a97933;;
                  border-bottom: 1px solid #a97933;
                  margin-bottom: 30px;
                  font-size: 24px;
                  font-weight: 600;
                  @include middle();
                  @media (max-width: 1000px) { 
                    font-size: 22px;
                  }
                  @media (max-width: 600px) { 
                    font-size: 18px;
                  }
                }
                .message-invite-Contentbox {
                  width: 100%;
                  font-size: 18px;
                  padding: 20px;
                  box-sizing: border-box;
                  line-height: 30px;
                  white-space: pre-line;
                  @media (max-width: 600px) { 
                    text-align: left;
                  }
                }
                .message-textBox {
                  margin-bottom: 20px;
                  .message-name {
                    font-size: 18px;
                    color: #a97933;
                    @media (max-width: 1000px) { 
                      font-size: 16px;
                    }
                  }
                  .message-dean {
                    font-size: 18px;
                    @media (max-width: 1000px) { 
                      font-size: 16px;
                    }
                  }
                }
                .message-Images-cover {
                  width: 100%;
                  @include middle();
                  img {
                    width: 300px;
                  }
                }
                .message-faculty-Contentbox {
                  margin-bottom: 20px;

                  .message-faculty-sort {
                    position: relative;
                    width: 100%;
                    text-align: left;
                    margin-bottom: 10px;

                    .facultysort {
                      font-weight: 600;
                      padding-left: 20px;
                      font-size: 22px;
                      @media (max-width: 1000px) { 
                        font-size: 18px;
                      }
                      @media (max-width: 600px) { 
                        font-size: 16px;
                      }
                    }
                    .facultysort::before {
                      position: absolute;
                      left: 0px;
                      top: 50%;
                      transform: translateY(-50%);
                      content: '';
                      width: 4px;
                      height: 20px;
                      background: #a97933;
                    }
                  }
                  .message-faculty-professor {
                    width: 100%;
                    display: grid;
                    justify-content: space-between;
                    grid-template-columns: 33% 33% 33%;
                    @media (max-width: 600px) { 
                      grid-template-columns: 50% 50%;
                    }

                    .message-faculty-professor-box {
                      width: 100%;
                      margin-bottom: 10px;
                      padding: 20px;
                      box-sizing: border-box;
                      .message-faculty-professor-images {
                        width: 100%;
                        img {
                          width: 100%;
                        }
                      }
                      .message-faculty-professor-part {
                        margin-top: 10px;
                        width: 100%;
                        font-size: 20px;
                        color: #8C8C8C;
                        @media (max-width: 1000px) { 
                          font-size: 18px;
                        }
                        @media (max-width: 600px) { 
                          font-size: 16px;
                        }
                      }
                      .message-faculty-professor-names {
                        width: 100%;
                        @include middle();
                        .professor-names-main {
                          font-size: 24px;
                          font-weight: 600;
                          margin-right: 10px;
                          word-break: keep-all;
                          @media (max-width: 1000px) { 
                            font-size: 18px;
                          }
                          @media (max-width: 600px) { 
                            font-size: 16px;
                          }
                        }
                        .professor-names-sub {
                          color: #8C8C8C;
                          font-size: 18px;
                          @media (max-width: 1000px) { 
                            font-size: 16px;
                          }
                        }
                      }
                    }
                  }
                }
                .message-mapBtnBox {
                  width: 100%;
                  margin-top: 50px;
                  margin-bottom: 50px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  .message-mapBtn {
                    width: 100%;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    border: 1px solid #BDBDBD;
                    padding: 10px;
                    box-sizing: border-box;
                    border-radius: 5px;
                    @media (max-width: 1000px) { 
                      height: 60px;
                      margin-bottom: 20px;
                      p {
                        width: 80%;
                        font-size: 16px;
                      }
                    }

                    .message-mapBtnImg {
                      width: 20%;
                      @media (max-width: 1000px) { 
                        @include middle();
                      }

                      img {
                        width: 50px;
                        @media (max-width: 1000px) { 
                          width: 40px;
                        }
                      }
                      .message-icon {
                        width: 50px;
                        height: 50px;
                        border: 1px solid #BDBDBD;
                        border-radius: 25px;
                        @include middle();
                        @media (max-width: 1000px) { 
                          width: 40px;
                          height: 40px;
                          border-radius: 20px;
                        }
                      }
                    }
                    .message-map-text {
                      width: 80%;
                    }
                    .message-map-icon {
                      width: 10%;
                      color: #8C8C8C;
                    }
                  }
                  .message-mapBtn:hover {
                    border: 1px solid #919191;
                  }
                }
              }
              
            }
          }
          // comment -----------------------
      
          .commentMainBox {
            width: 100%;
            margin-top: 50px;
            margin-bottom: 100px;
          
            .commentPostBox {
              width: 100%;

              .commentTitle {
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
              p {
                font-size: 18px;
                margin-left: 20px;
                position: relative;
                margin-bottom: 10px;
                @media (max-width: 600px) { 
                  font-size: 16px;
                }
              }
              p:before {
                position: absolute;
                left: -15px;
                top: 50%;
                transform: translateY(-50%);
                content: '';
                width: 3px;
                height: 15px;
                background: #334968;
              }
              .commentinput,
              .commenttextarea {
                width: 100%;
                font-size: 15px;
                font-weight: normal;
                min-height: 40px;
                padding: 10px;
                box-sizing: border-box;
                border: 1px solid #E9E9E9;
                border-radius: 5px;
                margin-bottom: 10px;
              }
              .commentinput {
                min-height: 50px;
              }
              .commenttextarea {
                min-height: 200px;
                outline: none;
                line-height: 25px;
                resize: none;
              }
              .commentselect {
                width: 100%;
                height: 50px;
                padding: 4px 10px;
                border: 1px solid #E9E9E9;
                box-sizing: border-box;
                border-radius: 5px;
                color: #333;
                font-size: 16px;
                background-color: #fff;
                outline: none;
                margin-bottom: 10px;
              }
            }
            .buttonbox {
              width: 100%;
              height: 50px;
              margin-top: 10px;
              @include middle;
      
              .button {
                width: 300px;
                height: 100%;
                background-color: #33383f;
                border: 1px solid #ccc;
                box-sizing: border-box;
                border-radius: 5px;
                color: #fff;
                cursor: pointer;
                @include middle;
                font-size: 18px;
                @media (max-width: 600px) { 
                  font-size: 16px;
                }
              }
              .button:hover {
                opacity: 0.8;
              }
            }
            .commentsbox {
              width: 100%;
              padding: 20px;
              margin: 20px 0;
              box-sizing: border-box;
              border-bottom: 1px dashed #feebce;;
                  
              .namebox {
                display: flex;
                align-items: end;
                justify-content: space-between;
                margin-bottom: 10px;
                .namebox-row {
                  display: flex;
                  align-items: center;
                  h3 {
                    font-size: 18px;
                    margin-right: 10px;
                    font-weight: 600;
                    @media (max-width: 600px) { 
                      font-size: 16px;
                    }
                  }
                  .namebox-icon {
                    margin-right: 7px;
                    color: #8C8C8C;
                  }
                }
                p {
                  font-size: 16px;
                  margin-left: 10px;
                  @media (max-width: 600px) { 
                    font-size: 14px;
                  }
                }
              }
              .textbox {
                padding-left: 10px;
    
                p {
                  font-size: 20px;
                  white-space: pre-line;
                  @media (max-width: 600px) { 
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
        
      }
      
    }
  }

}
