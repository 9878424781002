@mixin middle {
  display: flex;
  justify-content: center;
  align-items: center;
};

.pamphlet {

  > .inner {
    
    .subpage__menu {
      @media (max-width: 600px) { 
        display: none;
      }
    }

    .subpage__main {
       
    
      .subpage__main__content {

          .main__content {
        
            // solo-program -----------------------
        
            .programbox {
              width: 100%;
              margin-top: 50px;
              margin-bottom: 100px;
              text-align: center;
              @include middle;
              flex-direction: column;
              background-color: #fffef4;
              padding: 30px;
              box-sizing: border-box;
              @media (max-width: 600px) { 
                padding: 15px;
              }
        
              .program-title {
                width: 100%;
                text-align: center;
                font-size: 24px;
                font-weight: bold;
              }
              .program-cover {
                width: 100%;
        
                .program-list {
                  text-align: left;
                  margin-top: 50px;
                  margin-bottom: 50px;
                  position: relative;
                          
                  .program-graduate-row {
                    display: flex;
                    align-items: end;
                    h1 {
                      font-size: 24px;
                      font-weight: 600;
                      margin-right: 20px;
                    }
                    h3 {
                      font-size: 20px;
                    }
                  }

                  .InterMission {
                    font-size: 24px;
                    height: 80px;
                    border-top: 1px solid #a97933;
                    border-bottom: 1px solid #a97933;
                    @include middle();
                    color: #a97933;
                  }
                  .program-composition-textrow {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    @media (max-width: 1000px) { 
                      margin-bottom: 10px;
                    }
                    
                    .program-composition {
                      font-size: 20px;
                      color: #a97933;
                      @media (max-width: 1000px) { 
                        font-size: 18px;
                      }
                    }
                    .program-notice-lyrics-btn-box {
                      display: flex;
                      
                      .program-notice-lyrics-btn {
                        width: 70px;
                        height: 35px;
                        border: 1px solid #e7e7e7;
                        border-radius: 10px;
                        box-sizing: border-box;
                        margin-left: 10px;
                        @include middle;
                        background-color: #fdfdf7;
                        @media (max-width: 1000px) { 
                          width: 60px;
                          height: 30px;
                        }
        
                        .program-notice-lyrics-btn-text {
                          font-size: 16px;
                          margin-right: 5px;
                          color: #8C8C8C;
                          @media (max-width: 1000px) { 
                            font-size: 14px;
                          }
                        }
                        .program-notice-lyrics-btn-icon {
                          font-size: 16px;
                          color: #a97933;;
                          @media (max-width: 1000px) { 
                            font-size: 14px;
                          }
                        }
                      }
                      .program-notice-lyrics-btn:hover {
                        border: 2px solid #333;
                        cursor: pointer;
                      }
                    }
                    
                  }
                
                  .program-songname-box {

                    .program-songname {
                      font-size: 24px;
                      margin-left: 20px;
                      @media (max-width: 1000px) { 
                        font-size: 22px;
                      }
                    }
                   
                    .program-notice-lyrics-box{
                       margin-top: 10px;
                      @media (max-width: 1000px) { 
                        margin-top: 30px;    
                      }      

                      .program-notice-Contentbox {
                        width: 100%;
                        font-size: 18px;
                        border-top: 1px dashed #BDBDBD;
                        background-color: #f6f5e9;
                        padding: 20px;
                        z-index: 9;
                        border-radius: 5px;
                        box-sizing: border-box;
                        line-height: 30px;
                        white-space: pre-line;
                      }
                    }
                  }
                  .program-subsongname {
                    font-size: 20px;
                    margin-left: 30px;
                    @media (max-width: 1000px) { 
                      font-size: 18px;  
                    }      
                  }
                }
              }
            }

            // personal-program -----------------------
        
            .personal-programbox {
              width: 100%;
              margin-top: 50px;
              margin-bottom: 100px;
              text-align: center;
              @include middle;
              flex-direction: column;
              padding: 30px;
              box-sizing: border-box;
        
              .personal-program-title {
                width: 100%;
                text-align: center;
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 20px;
              }
              .personal-program-subtitle {
                width: 100%;
                text-align: center;
                font-size: 22px;
                border-top: 1px solid #a97933;
                border-bottom: 1px solid #a97933;
                color: #a97933;
              }
              .personal-program-cover {
                width: 100%;
        
                .personal-program-list {
                  text-align: left;
                  margin-top: 50px;
                  margin-bottom: 50px;
                  position: relative;
                  padding-bottom: 30px;
                  border-bottom: 1px dashed #a97933;
                          
                  .personal-program-many-titlerow {
                    display: flex;
                    justify-content: space-between;
                    align-items: start;

                    .personal-program-many-titlerow-left {
                      display: flex;
                      align-items: start;
                      flex-direction: column;
                      h3 {
                        font-size: 20px;
                        height: 27px;
                        @media (max-width: 1000px) { 
                          font-size: 18px;
                        }
                        @media (max-width: 600px) { 
                          font-size: 16px;
                        }
                      }
                      h1 {
                        font-size: 24px;
                        font-weight: 600;
                        @media (max-width: 1000px) { 
                          font-size: 22px;
                        }
                        @media (max-width: 600px) { 
                          font-size: 22px;
                        }
                      }
                    }
                    .personal-program-many-titlerow-right {
                      text-align: right;
                      font-size: 20px;
                      @media (max-width: 1000px) { 
                        font-size: 18px;
                      }
                      @media (max-width: 600px) { 
                        font-size: 15px;
                      }
                    }
                  }
                  .personal-program-many-subPlayers-row {
                    padding-left: 20px;
                    text-align: right;
                    h3 {
                      margin-right: 15px;
                      color: #646464;

                      @media (max-width: 600px) { 
                        font-size: 15px;
                      }
                    }
                  }
                  .personal-program-many-contentrow {

                    .personal-program-many-contentrow-top {
                      margin-top: 20px;
                      
                      .personal-program-composition-textrow {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        
                        .personal-program-composition {
                          font-size: 20px;
                          color: #a97933;
                          @media (max-width: 1000px) { 
                            font-size: 18px;
                          }
                          @media (max-width: 600px) { 
                            font-size: 16px;
                          }
                        }
                        
                      }
                    
                      .personal-program-songname-box {
    
                        .personal-program-songname {
                          font-size: 22px;
                          margin-left: 20px;
                          @media (max-width: 1000px) { 
                            font-size: 20px;
                          }
                        }
                      }
                      .personal-program-subsongname {
                        font-size: 20px;
                        margin-left: 30px;
                        color: #8C8C8C;
                        @media (max-width: 1000px) { 
                          font-size: 18px;
                        }
                      }
                    }
                    .personal-program-many-contentrow-bottom {
                      display: flex;
                      justify-content: right;
                      @media (max-width: 600px) { 
                        margin-top: 10px;
                      }

                      .personal-program-moreBtn {
                        width: 100px;
                        height: 30px;
                        border: 1px solid #BDBDBD;
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #8C8C8C;
                        @media (max-width: 1000px) { 
                          width: 90px;
                          height: 30px;
                        }
                        @media (max-width: 600px) { 
                          width: 80px;
                          height: 25px;
                        }
                        p {
                          margin-right: 5px;
                          font-size: 20px;
                          @media (max-width: 1000px) { 
                            font-size: 18px;
                          }
                          @media (max-width: 1000px) { 
                            font-size: 16px;
                          }
                        }
                      }
                      .personal-program-moreBtn:hover {
                        cursor: pointer;
                        border: 1px solid #9b9b9b;
                      }
                    }
                  }
                  
                  
                }
              }
            }

            // complex-program -----------------------
        
            .complex-programbox {
              width: 100%;
              margin-top: 50px;
              margin-bottom: 100px;
              text-align: center;
              @include middle;
              flex-direction: column;
              padding: 30px;
              box-sizing: border-box;
        
              .complex-program-title {
                width: 100%;
                text-align: center;
                font-size: 24px;
                font-weight: bold;
              }
              .complex-program-cover {
                width: 100%;
        
                .complex-program-list {
                  text-align: left;
                  margin-top: 50px;
                  margin-bottom: 50px;
                  position: relative;
                  padding-bottom: 30px;
                  border-bottom: 1px dashed #a97933;
                          
                  .complex-program-many-titlerow {
                    display: flex;
                    justify-content: space-between;
                    align-items: start;
                    @media (max-width: 600px) { 
                     flex-direction: column;
                    }

                    .complex-program-many-titlerow-left {
                      display: flex;
                      align-items: start;
                      flex-direction: column;
                      h1 {
                        font-size: 24px;
                        font-weight: 600;
                        margin-right: 20px;
                        @media (max-width: 1000px) { 
                          font-size: 22px;
                        }
                        @media (max-width: 600px) { 
                          font-size: 20px;
                        }
                      }
                    }
                    .complex-program-many-titlerow-right {
                      text-align: right;
                      font-size: 20px;
                      flex-wrap: wrap;
                      @media (max-width: 1000px) { 
                        font-size: 18px;
                      }
                      @media (max-width: 600px) { 
                        width: 100%;
                        display: flex;
                        justify-content: end;
                        font-size: 16px;
                      }
                      .complex-program-many-titlerow-bar {
                        display: none;
                        @media (max-width: 600px) { 
                          display: flex;
                          margin: 0 5px;
                        }
                      }
                      h3 {
                        font-size: 20px;
                        @media (max-width: 1000px) { 
                          font-size: 18px;
                        }
                        @media (max-width: 600px) { 
                          font-size: 16px;
                        }
                      }
                    }
                  }
                  .complex-program-many-subPlayers-row {
                    padding-left: 20px;
                    h3 {
                      margin-right: 15px;
                      color: #646464;
                      @media (max-width: 600px) { 
                        font-size: 16px;
                      }
                    }
                  }
                  .complex-program-many-contentrow {

                    .complex-program-many-contentrow-top {
                      margin-top: 30px;
                      
                      .complex-program-composition-textrow {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        
                        .complex-program-composition {
                          font-size: 20px;
                          color: #a97933;
                          @media (max-width: 1000px) { 
                            font-size: 18px;
                          }
                          @media (max-width: 600px) { 
                            font-size: 16px;
                          }
                        }
                        
                      }
                    
                      .complex-program-songname-box {
    
                        .complex-program-songname {
                          font-size: 24px;
                          margin-left: 20px;
                          @media (max-width: 1000px) { 
                            font-size: 22px;
                          }
                        }
                      }
                      .complex-program-subsongname {
                        font-size: 20px;
                        margin-left: 30px;
                        @media (max-width: 1000px) { 
                          font-size: 16px;
                          color: #8C8C8C;
                        }
                      }
                    }
                    .complex-program-many-contentrow-bottom {
                      display: flex;
                      justify-content: right;
                      @media (max-width: 600px) { 
                        margin-top: 10px;
                      }

                      .complex-program-moreBtn {
                        width: 100px;
                        height: 30px;
                        border: 1px solid #BDBDBD;
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #8C8C8C;
                        @media (max-width: 1000px) { 
                          width: 90px;
                          height: 30px;
                        }
                        @media (max-width: 600px) { 
                          width: 80px;
                          height: 25px;
                        }
                        p {
                          margin-right: 5px;
                          font-size: 20px;
                          @media (max-width: 1000px) { 
                            font-size: 18px;
                          }
                          @media (max-width: 1000px) { 
                            font-size: 16px;
                          }
                        }
                      }
                      .complex-program-moreBtn:hover {
                        cursor: pointer;
                        border: 1px solid #9b9b9b;
                      }
                    }
                  }
                  
                  
                }
              }
            }
       
            // career -----------------------
        
            .careerbox {
              width: 100%;
              margin-top: 50px;
              margin-bottom: 100px;
              text-align: center;
              @include middle;
              flex-direction: column;
        
              .career-title {
                width: 100%;
                text-align: left;
                font-size: 24px;
                font-weight: bold;
              }
              .career-container {
                margin-top: 20px;
                margin-bottom: 30px;
                display: flex;
                @media (max-width: 600px) { 
                  flex-direction: column;
                }
        
                .career-playerImage {
                  width: 40%;
                  text-align: left;
                  display: flex;
                  align-items: start;
                  @media (max-width: 600px) { 
                    width: 100%;
                  }

                  .career-image {
                    width: 100%;
                    margin-right: 30px;
                    @media (max-width: 600px) { 
                      margin-right: 0px;
                      margin-bottom: 50px;
                    }
        
                    img {
                      width: 100%;
                    }
                  }

                }
                .career-content {
                  width: 60%;
                  @media (max-width: 600px) { 
                    width: 100%;
                  }

                  .career-namecover {
                    height: 80px;
                    border-top: 2px solid #a97933;;
                    border-bottom: 1px solid #a97933;;
                    display: flex;
                    align-items: center;
                    margin-bottom: 30px;
                    
                    .career-part {
                      margin-right: 20px;
                      color: #a97933;
                    }
                    .career-name{
                      font-size: 26px;
                      font-weight: 600;
                    }
                  }
        
                  .career-listcover {
                      
                    .career-writting{
                      font-size: 18px;
                      line-height: 35px;
                      text-align: left;
                      white-space: pre-line;
                      word-break: break-all;
                    }
                    .career-list {
                      text-align: left;
                      margin-top: 10px;
                      margin-bottom: 10px;
                      font-size: 18px;
                      white-space: pre-line;
                    }
                  }
                }
                
              }
             
            }

            // personal-career -----------------------
        
            .personal-careerbox {
              width: 100%;
              margin-top: 50px;
              margin-bottom: 100px;
              text-align: center;
              @include middle;
              flex-direction: column;
        
              .personal-career-title {
                width: 100%;
                text-align: left;
                font-size: 24px;
                font-weight: bold;
              }
              .personal-career-container {
                width: 100%;
                margin-top: 20px;
                margin-bottom: 30px;
                display: flex;
                @media (max-width: 600px) { 
                  flex-direction: column;
                }
        
                .personal-career-playerImage {
                  width: 40%;
                  text-align: left;
                  display: flex;
                  align-items: start;
                  @media (max-width: 600px) { 
                    width: 100%;
                    margin-bottom: 30px;
                  }

                  .personal-career-image {
                    margin-right: 30px;
                    @media (max-width: 600px) { 
                      margin-right: 0px;
                    }

                    img {
                      width: 100%;
                    }
                  }

                }
                .personal-career-content {
                  width: 60%;
                  @media (max-width: 600px) { 
                    width: 100%;
                  }
                  
                  .personal-career-namecover {
                    width: 100%;
                    height: 80px;
                    border-top: 2px solid #a97933;;
                    border-bottom: 1px solid #a97933;;
                    display: flex;
                    align-items: center;
                    margin-bottom: 30px;
                    @media (max-width: 600px) { 
                      justify-content: center;
                    }
                    
                    .personal-career-part {
                      margin-right: 20px;
                      color: #a97933;
                      @media (max-width: 600px) { 
                        margin-right: 18px;
                      }
                    }
                    .personal-career-name{
                      font-size: 26px;
                      font-weight: 600;
                      @media (max-width: 600px) { 
                        font-size: 22px;
                      }
                    }
                  }
        
                  .personal-career-listcover {
                    padding-bottom: 30px;
                    border-bottom: 1px dashed #BDBDBD;
                     
                    .personal-career-writting{
                      font-size: 18px;
                      line-height: 35px;
                      white-space: pre-line;
                    }
                    .personal-career-list {
                      text-align: left;
                      margin-top: 10px;
                      margin-bottom: 10px;
                      font-size: 18px;
                    }
                  }
                }
                
              }
              .many-songs-container {
                
                .many-songs-composition {
                  font-size: 20px;
                  color: #a97933;
                }
                .many-songs-subsongname {
                  font-size: 18px;
                  color: #8C8C8C;
                }
                .many-songs-notice-lyrics-box{
                  margin-top: 30px;      

                 .many-songs-notice-Contentbox {
                   width: 100%;
                   font-size: 18px;
                   border-top: 1px dashed #BDBDBD;
                   padding: 20px;
                   z-index: 9;
                   border-radius: 5px;
                   box-sizing: border-box;
                   line-height: 30px;
                   white-space: pre-line;
                    word-break: break-all;
                    @media (max-width: 600px) { 
                      text-align: left;
                    }
                 }
               }
              }
              .many-buttonbox {
                width: 100%;
                height: 50px;
                margin-top: 30px;
                @include middle;
        
                .many-button {
                  width: 300px;
                  height: 100%;
                  background-color: #33383f;
                  border: 1px solid #ccc;
                  box-sizing: border-box;
                  border-radius: 5px;
                  color: #fff;
                  cursor: pointer;
                  @include middle;
                  font-size: 18px;
                  @media (max-width: 600px) { 
                    font-size: 16px;
                  }
                }
                .many-button:hover {
                  opacity: 0.8;
                }
              }
            }
           

            // complex-profile -----------------------
        
            .complex-profilebox {
              width: 100%;
              margin-top: 50px;
              margin-bottom: 100px;
              text-align: center;
              @include middle;
              flex-direction: column;

              .complex-profile-container {
                width: 90%;
                margin-top: 20px;
                margin-bottom: 30px;
                display: flex;
                box-sizing: border-box;
                @media (max-width: 600px) { 
                  flex-direction: column;
                }
                .complex-profile-playerImage {
                  width: 40%;
                  text-align: left;
                  display: flex;
                  align-items: start;
                  @media (max-width: 600px) { 
                    width: 100%;
                  }
                  .complex-profile-image {
                    width: 100%;
                    margin-left: 30px;
                  
                    @media (max-width: 600px) { 
                      margin-left: 0px;
                      margin-bottom: 50px;
                    }
                    img {
                      width: 100%;
                    }
                  }

                }
                .complex-profile-content {
                  width: 60%;
                  @media (max-width: 600px) { 
                    width: 100%;
                  }

                  .complex-profile-namecover {
                    width: 100%;
                    height: 80px;
                    border-top: 2px solid #a97933;;
                    border-bottom: 1px solid #a97933;;
                    display: flex;
                    align-items: center;
                    margin-bottom: 30px;
                    
                    .complex-profile-part {
                      margin-right: 20px;
                      color: #a97933;
                      font-size: 20px;
                      @media (max-width: 1000px) { 
                        font-size: 18px;
                      }
                    }
                    .complex-profile-name{
                      font-size: 24px;
                      font-weight: 600;
                      @media (max-width: 1000px) { 
                        font-size: 22px;
                      }
                    }
                  }
        
                  .complex-profile-listcover {
                      
                    .complex-profile-writting{
                      font-size: 18px;
                      line-height: 35px;
                      text-align: left;
                      white-space: pre-line;
                      word-break: keep-all;
                    }
                    .complex-profile-list {
                      text-align: left;
                      margin-top: 10px;
                      margin-bottom: 10px;
                      font-size: 18px;
                      white-space: pre-line;
                    }
                  }
                  .forpc {
                  
                    @media (max-width: 600px) { 
                      display: none;
                    }
                  }
                  .formobile {
                    display: none;
                    @media (max-width: 600px) { 
                      display: block;
                    }
                  }
                  
                }
                .formobile {
                  display: none;
                  @media (max-width: 600px) { 
                    display: block;
                  }
                }
              }
              
            }
            .complex-profile-buttonbox {
              width: 100%;
              height: 50px;
              margin-top: 30px;
              @include middle;
      
              .complex-profile-button {
                width: 300px;
                height: 100%;
                background-color: #33383f;
                border: 1px solid #ccc;
                box-sizing: border-box;
                border-radius: 5px;
                color: #fff;
                cursor: pointer;
                @include middle;
                font-size: 18px;
                @media (max-width: 600px) { 
                  font-size: 16px;
                }
              }
              .complex-profile-button:hover {
                opacity: 0.8;
              }
            }
            
            .divider {
              width: 100%;
              height: 1px;
              background-color: #EAEAEA;
              margin: 20px 0;
            } 
          }
        
      }
      
    }
  }
}

