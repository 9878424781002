@mixin middle {
  display: flex;
  justify-content: center;
  align-items: center;
};

.mypage {
  padding-top: 50px;

  > .inner {
    display: flex;
    justify-content: space-between;

    .subpage__menu {
      width: 20%;
      margin-right: 32px;
  
      .subpage__menu__title {
        position: relative;
        font-size: 24px;
        font-weight: 600;
        color: #000;
        padding-left: 30px;
      }
  
      .subpage__menu__title:before {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        width: 4px;
        height: 24px;
        background: #33383f;
      }
  
      .subpage__menu__list {
          padding-top: 38px;
  
        .subpage__menu__item {
          display: block;
          width: 100%;
          padding: 21px 0 21px 20px;
          font-size: 18px;
          color: #000;
          border-bottom: 1px solid #cbcbcb;
          line-height: 1;
        }
    
        .subpage__menu__item:first-child {
            border-top: 1px solid #cbcbcb;
        }
    
        .subpage__menu__item:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    
        .subpage__menu__item--on {
            color: #fff;
            background: #33383f;
        }
    
        .subpage__menu__item--on:hover {
            text-decoration: none;
        }
      }
  
      
    }

    .subpage__main {
      width: 75%;
      min-height: 100vh;

      .subpage__main__title {
        font-size: 30px;
        font-weight: 600;
        color: #33383f;
      }
      .subpage__main__content {
          padding-top: 30px;
          font-size: 20px;
          color: #333;

        .board .subpage__main__content {
          font-size: 16px;
        }
        
        .main__title {
            position: relative;
            font-size: 26px;
            color: #1B1B1B;
            padding: 24px 0;
            margin-bottom: 20px;
            word-break: keep-all;
            line-height: 1.54;
        }
        
        .main__title:after {
            position: absolute;
            content: '';
            width: 28px;
            height: 3px;
            background: #1B1B1B;
            left: 0;
            bottom: 0;
        }
        
        .main__content {
          line-height: 1.9;
          display: flex;
          
          .imagearea {
            align-items: center;
            padding: 20px;
            box-sizing: border-box;

            .imagebox {
              width: 200px;
              height: 200px; 
              background-color:#fff;
              @include middle();
              border: 1px solid #c1c1c1;
              border-radius: 5px;

              img {
                width: 100%;
                max-height: 200px;
                object-fit: contain;
              }

              .imageDropzoneStyle {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                border: 2px dashed #BDBDBD;
                cursor: pointer;
          
                div.imageplus {
                  font-size: 30px;
                  color: #333;
                }
              };
            
            }
          }
          .textarea {
            padding: 10px;
            padding-left: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;

            .textrow {
              display: flex;
              margin: 5px 0;
              align-items: center;
              
              h3 {
                width: 100px;
                font-size: 16px;
                font-weight: 600;
              }
              p {
                width: 60%;
                font-size: 16px;
              }
              .profileinputdefault {
                width: 430px;
                font-size: 16px;
                font-weight: normal;
                height: 40px;
                padding-left: 10px;
                box-sizing: border-box;
                border: none;
                border-bottom: 2px solid #E9E9E9;
              }
            }
          }
        }
        .divider {
          width: 100%;
          height: 1px;
          background-color: #1B1B1B;
          margin: 20px 0;
        }
        
      }
      .reviseBtn {
        margin: 20px 0;
        width: 150px;
        border: 1px solid #EAEAEA;
        border-radius: 5px;
        padding: 5px;
        text-align: center;

        p {
          font-size: 16px;
        }
      }
      .reviseBtn:hover {
        cursor: pointer;
        border: 1px solid #d7d7d7;
      }

      .accountDeleteBtn {
        width: 100%;
        display: flex;
        justify-content: end;

        .deleteBtnBox {
          .deleteBtn {
            text-decoration: underline;
            color: #8C8C8C;
            font-size: 14px;
          }
          .deleteBtn:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        }
      }

      .deleteAccountBox {
        @include middle();
        border: 1px solid red;
        padding: 20px;

        .deleteAccountCover {
          width: 80%;
          text-align: center;

          h1 {
            font-size: 24px;
            margin-bottom: 10px;
          }
          h2 {
            font-size: 20px;
            margin-bottom: 10px;
          }
          div {
            font-size: 18px;
            margin-bottom: 15px;
            p {
              margin-bottom: 5px;
            }
          }
        }
      }
      .deletecheckInputCover {
        width: 90%;
        display: flex;
        justify-content: center;
        margin: 20px 0;

        .deletecheckInput {
          display: flex;
          align-items: center; 
          justify-content: center;
          margin-right: 10px;

          .deleteinput {
            width: 20px;
            height: 20px;
          }
          > h5 {
            font-size: 20px;
            margin-left: 10px;
            flex: 1;
          }
        }
      }
    }
    
    
  }
  

  
}

@media (max-width: 1000px) { 
  .mypage {
  
    > .inner {

      .subpage__menu { 
        display: none;
      }
      .subpage__main { 
        width: 100%;

        .subpage__main__title {
          font-size: 20px;
        }

        .subpage__main__content {
          font-size: 14px;
          padding-top: 14px;

          .main__content {
            padding-left: 10px;

            .imagearea {
             flex-direction: column;

             .sortcover {
              margin-top: 20px;
             }
            }
            .textrow {
              
              h3 {
                width: 30%;
                font-size: 15px;
              }
              p {
                width: 70%;
                font-size: 15px;
              }
            }
            .inputbox {
              width: 90%;
              p {
                width: 90%;
              }
              .inputdefault {
                width: 90%;
              }
            }
          }
        }

        .deleteAccountBox {
            
          .deleteAccountCover {
            width: 100%;
  
            h1 {
              font-size: 20px;
            }
            h2 {
              font-size: 18px;
            }
            div {
              font-size: 16px;

            }
          }
        }
      }
    }
  }
}

